import axios from "../axios";
import {
  SECTION_CREATE_FAIL,
  SECTION_CREATE_REQUEST,
  SECTION_CREATE_SUCCESS,
  SECTION_DELETE_FAIL,
  SECTION_DELETE_REQUEST,
  SECTION_DELETE_SUCCESS,
  SECTION_LIST_FAIL,
  SECTION_LIST_REQUEST,
  SECTION_LIST_SUCCESS,
  SECTION_UPDATE_FAIL,
  SECTION_UPDATE_REQUEST,
  SECTION_UPDATE_SUCCESS,
} from "../constants/sectionConstants";
import { logout } from "./userActions";

export const createSection = (category) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SECTION_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
      sectionList: { sections },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const form = new FormData();

    form.append("name", category.name);
    form.append("image", category.image);
    form.append("organizer", category.organizer);

    // last
    // const { data } = await axios.post(`/sections/add/`, form, config);
    // new
    const { data } = await axios.post(`/sections/new-add/`, form, config);

    dispatch({
      type: SECTION_CREATE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SECTION_LIST_SUCCESS,
      payload: { sections: [...sections, data.section] },
    });
  } catch (error) {
    const message =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SECTION_CREATE_FAIL,
      payload: message,
    });
  }
};

export const deleteSection = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SECTION_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
      sectionList: { sections },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(`/sections/${id}/delete/`, config);

    dispatch({
      type: SECTION_DELETE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: SECTION_LIST_SUCCESS,
      payload: { sections: sections.filter((el) => el._id !== id) },
    });
  } catch (error) {
    const message =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: SECTION_DELETE_FAIL,
      payload: message,
    });
  }
};
export const updateSection = (id, section) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SECTION_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
      sectionList: { sections },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const form = new FormData();

    form.append("name", section.name);
    form.append("image", section.image);

    const { data } = await axios.put(`/sections/${id}/update/`, form, config);

    dispatch({
      type: SECTION_LIST_SUCCESS,
      payload: {
        sections: sections.map((el) =>
          el._id === data.section._id ? data.section : el
        ),
      },
    });
    dispatch({
      type: SECTION_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }

    dispatch({
      type: SECTION_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const listSection =
  (organizerSelect = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: SECTION_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      // last
      // const { data } = await axios.get(`/sections/`, config);
      // new
      const { data } = await axios.get(
        `/sections/new-list/?organizer=` + organizerSelect,
        config
      );

      dispatch({
        type: SECTION_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      console.log(message);

      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }

      dispatch({
        type: SECTION_LIST_FAIL,
        payload: message,
      });
    }
  };

export const listSectionByOrganizer = (organizer) => async (dispatch) => {
  try {
    dispatch({
      type: SECTION_LIST_REQUEST,
    });

    const { data } = await axios.get(`/sections/organizer/${organizer}/`);

    dispatch({
      type: SECTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    console.log(message);

    dispatch({
      type: SECTION_LIST_FAIL,
      payload: message,
    });
  }
};
