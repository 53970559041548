import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../axios";
import { useTranslation } from "react-i18next";

function ProductOverview({ organizerSelect = "" }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { t } = useTranslation();

  const [overview, setOverview] = useState([]);

  useEffect(() => {
    if (userInfo) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      // last
      // axiosInstance
      //   .get("/stats/overview/", config)
      //   .then((res) => {
      //     // console.log(res.data)
      //     setOverview(res.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // new
      axiosInstance
        .get("/stats/new-overview/?organizer=" + organizerSelect, config)
        .then((res) => {
          // console.log(res.data)
          setOverview(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [organizerSelect]);

  return (
    <div>
      <div className="pb-3 pt-6">
        <h1>{t("Product Overview")}</h1>
      </div>
      <div class="flex flex-col bg-white rounded-md">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden">
              <table class="min-w-full">
                <thead class="bg-white capitalize border-b">
                  <tr>
                    {/* <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            #
                                        </th> */}
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      {t("Product")}
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      {t("Quantity")}
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      {t("Quantity sold")}
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      {t("Online")}
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      {t("Offline")}
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      {t("Amount")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {overview.map((item, id) => (
                    <tr
                      key={item._id}
                      class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                    >
                      {/* <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            <a className='text-blue-600' href={`#`}>
                                                #{id+1}
                                            </a>
                                            </td> */}
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {item.product.name}
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {item.product.countInStock + item.order.qty}
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {item.order.qty}
                      </td>

                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {item.order.sold_online}
                      </td>
                      <td class="text-sm relative text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {item.order.sold_offline}
                      </td>
                      <td class="text-sm relative text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {item.order.amount}
                      </td>
                    </tr>
                  ))}

                  <tr className="h-16"></tr>

                  {/* <div className=''>
                                        <Paginate page={page} pages={pages} />

                                    </div> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductOverview;
