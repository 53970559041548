import { useState } from "react";
import SideBar from "../components/SideBar";
import SideBarMobile from "../components/SideBarMobile";

const AdminLayout = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="admin-screen min-h-screen bg-primary p-4">
      <div className="flex">
        <div className="">
          <div className="md:block hidden text-white ">
            <SideBar />
          </div>

          <div
            className={`absolute left-0 top-0 z-50 ${
              isOpen ? "block" : "hidden"
            }`}
          >
            <SideBarMobile setIsOpen={setIsOpen} />
          </div>
        </div>

        <div className="p-4 rounded-xl flex-1 bg-[#F5F5F5]  h-screen overflow-y-scroll   w-full">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="absolute md:hidden block left-0 top-0 bg-primary p-2 rounded-md text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
          {isOpen && (
            <div
              onClick={() => setIsOpen(false)}
              className="w-screen h-screen bg-gray-200 bg-opacity-80  fixed top-0 right-0 left-0 bottom-0"
            ></div>
          )}

          {props.children}
        </div>
      </div>
    </div>
  );
};
export default AdminLayout;
