import axios from "../axios"
import { EXPIRED_ORGANIZER_LIST_FAIL, EXPIRED_ORGANIZER_LIST_REQUEST, EXPIRED_ORGANIZER_LIST_SUCCESS, ORGANIZER_DETAILS_FAIL, ORGANIZER_DETAILS_REQUEST, ORGANIZER_DETAILS_SUCCESS, ORGANIZER_LIST_FAIL, ORGANIZER_LIST_REQUEST, ORGANIZER_LIST_SUCCESS } from "../constants/organizerConstants"


export const listOrganizers = () => async (dispatch, getState) => {
    try {

        dispatch({
            type: ORGANIZER_LIST_REQUEST,
        })

        const {
          userLogin: { userInfo },
        } = getState()
        
   
        const { data } = await axios.get(`/organizers/`)

        dispatch({
            type: ORGANIZER_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        const message =
        error.response && error.response.data.message
            ? error.response.data.message
            : error.message
         

        dispatch({
            type: ORGANIZER_LIST_FAIL,
            payload: message,
        })
    }
}

export const listExpiredOrganizers = () => async (dispatch, getState) => {
  try {

      dispatch({
          type: EXPIRED_ORGANIZER_LIST_REQUEST,
      })

      // const {
      //   userLogin: { userInfo },
      // } = getState()
      
 
      const { data } = await axios.get(`/organizers/expired/`)

      dispatch({
          type: EXPIRED_ORGANIZER_LIST_SUCCESS,
          payload: data,
      })

  } catch (error) {
      const message =
      error.response && error.response.data.message
          ? error.response.data.message
          : error.message
       

      dispatch({
          type: EXPIRED_ORGANIZER_LIST_FAIL,
          payload: message,
      })
  }
}
export const listOrganizerBySlugDetails = (slug) => async (dispatch) => {
    try {
      dispatch({ type: ORGANIZER_DETAILS_REQUEST })
  
      const { data } = await axios.get(`/organizers/slug/${slug}/`)
  
      dispatch({
        type: ORGANIZER_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ORGANIZER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }