import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSection,
  deleteSection,
  listSection,
  updateSection,
} from "../actions/sectionActions";
import {
  SECTION_CREATE_RESET,
  SECTION_UPDATE_RESET,
} from "../constants/sectionConstants";
import Confirm from "./Confirm";
import Dialog from "./Dialog";
import Loader from "./Loader";
import Message from "./Message";
import { useTranslation } from "react-i18next";
import { baseURL, baseURLFiles } from "../axios";

const SectionItem = ({ section }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sectionUpdate = useSelector((state) => state.sectionUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    section: updatedSection,
  } = sectionUpdate;

  const [isShow, setIsShow] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [name, setName] = useState("");
  const [imageCategory, setImageCategory] = useState("");
  const [imageEidtCategory, setImageEidtCategory] = useState("");

  const handleEditSection = () => {
    dispatch(updateSection(section._id, { name, image: imageCategory }));
  };

  const showEditDialog = () => {
    setIsShow(true);
    setName(section.name);
    setImageEidtCategory(section.image);
  };

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: SECTION_UPDATE_RESET });
      setIsShow(false);
    }
  }, [successUpdate]);

  return (
    <>
      <div className=" flex flex-col" key={section._id}>
        <div className="w-32 shadow-sm bg-white rounded-md border p-2 h-32">
          <img
            className="h-full w-full rounded-md object-cover mr-3"
            src={`${
              baseURL === "/api/"
                ? section?.image
                : baseURLFiles + section.image
            }`}
          />
        </div>
        <div className="">
          <div className="">{section.name}</div>
          <div className="flex gap-x-3 text-xs pt-1 font-thin">
            <button onClick={showEditDialog}>{t("Edit")}</button>
            <button onClick={() => setOpenConfirm(true)}>{t("Delete")}</button>
          </div>
        </div>
        <div>
          <Dialog open={isShow} onClose={setIsShow}>
            <h1 className="text-xl font-semibold pb-6">{t("Edit Section")}</h1>
            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant={"danger"}>{errorUpdate}</Message>}
            <div className="border border-dashed flex items-center rounded-md mb-4 ">
              {imageEidtCategory && (
                <img
                  src={
                    baseURL === "/api/"
                      ? imageEidtCategory
                      : baseURLFiles + imageEidtCategory
                  }
                  className="m-2 w-10 h-10"
                  alt=""
                />
              )}
              <label htmlFor="formId" className="p-2 cursor-pointer">
                <input
                  id="formId"
                  className="p-2 outline-none bg-transparent w-full hidden"
                  type="file"
                  placeholder="image"
                  onChange={(e) => setImageCategory(e.target.files[0])}
                />
                <span className="text-blue-600">{t("Edit photo")} </span>
              </label>
            </div>
            <div className="border rounded-md mb-4 ">
              <input
                className="p-2 outline-none bg-transparent w-full"
                type="text"
                placeholder="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="pb-2 flex justify-end">
              <button
                className="mt-3 p-2 px-4 bg-primary rounded-full text-white"
                onClick={handleEditSection}
              >
                {t("Save")}
              </button>
            </div>
          </Dialog>
        </div>
      </div>
      <Confirm
        onClose={() => setOpenConfirm(false)}
        open={openConfirm}
        onConfirm={() => dispatch(deleteSection(section._id))}
      >
        {t("Are you sure to confirm this action !")}
      </Confirm>
    </>
  );
};

const AddSection = ({ setIsShow, organizerSelect = "" }) => {
  const [name, setName] = useState("");
  const [imageCategory, setImageCategory] = useState("");

  const sectionCreate = useSelector((state) => state.sectionCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    section: createdSection,
  } = sectionCreate;

  const dispatch = useDispatch();

  const handleAddSection = (event) => {
    event.preventDefault();
    // last
    // dispatch(createSection({ name, image: imageCategory, }));
    // new
    dispatch(
      createSection({ name, image: imageCategory, organizer: organizerSelect })
    );
  };

  useEffect(() => {
    if (successCreate) {
      dispatch({ type: SECTION_CREATE_RESET });
      setIsShow(false);
    }
  }, [successCreate]);

  const { t } = useTranslation();

  return (
    <div className="pt-2">
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant={"danger"}>{errorCreate}</Message>}
      <form onSubmit={handleAddSection}>
        <div
          className={`border mb-4 rounded-md ${
            imageCategory === "" ? "border-dashed " : "border-green-600"
          }`}
        >
          {imageCategory === "" ? (
            <label htmlFor="formIdAddImage">
              <input
                id="formIdAddImage"
                className="p-2 outline-none bg-transparent w-full hidden"
                type="file"
                placeholder="image"
                onChange={(e) => setImageCategory(e.target.files[0])}
              />
              <div className="p-2 text-blue-400 cursor-pointer">
                {t("Choose a photo")}
              </div>
            </label>
          ) : (
            <div className="p-2 flex items-center ">
              <img
                className="w-10 h-10 object-cover"
                src={URL.createObjectURL(imageCategory)}
                alt=""
              />
              <div className="pl-3">{imageCategory.name}</div>
            </div>
          )}
        </div>
        <div className="border mb-4 rounded-md ">
          <input
            className="p-2 outline-none bg-transparent w-full"
            type="text"
            placeholder="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-6 flex justify-end">
          <button className="whitespace-nowrap p-2 bg-primary  rounded-full px-3 text-white">
            {t("Save")}
          </button>
        </div>
      </form>
    </div>
  );
};

function Sections({ organizerSelect = "" }) {
  const sectionList = useSelector((state) => state.sectionList);
  const { loading, error, sections } = sectionList;

  const [isShow, setIsShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listSection(organizerSelect));
  }, [dispatch, organizerSelect]);

  const { t } = useTranslation();

  return (
    <div className="flex">
      <div className=" flex flex-wrap gap-3 ">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">Error : {error}</Message>
        ) : (
          sections?.map((section) => (
            <SectionItem key={section.id} section={section} />
          ))
        )}
        <button
          onClick={() => setIsShow(!isShow)}
          className="h-32 w-32 flex items-center justify-center bg-white rounded-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        </button>
      </div>

      <Dialog open={isShow} onClose={setIsShow}>
        <h1 className="text-xl font-semibold pb-6">{t("Add New Section")} </h1>
        <AddSection setIsShow={setIsShow} organizerSelect={organizerSelect} />
      </Dialog>
    </div>
  );
}

export default Sections;
