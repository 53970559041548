import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { deleteOrder, listOrders } from "../../actions/orderActions";
import { useHistory } from "react-router-dom";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import AdminLayout from "../../layout/AdminLayout";
import axiosInstance from "../../axios";
import { useTranslation } from "react-i18next";

const OrderItem = ({ order }) => {
  const ref = useRef(null);

  const history = useHistory();

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(ref, () => setIsOpen(false));

  const deleteHandler = (id) => (ev) => {
    dispatch(deleteOrder(id));
  };
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between mb-2 w-full py-2 px-4 rounded-xl shadow-md border">
      <div className="">
        {/* <div className='w-20 float-left'>
          <img src={order.product?.images[0]?.image} alt="" />
        </div> */}
        <div className="pl-2 float-right">
          <div className="text-xl pb-2">
            <a
              href={`https://aylink.ma/api/orders/${order._id}/export-ticket/`}
            >
              #{order.orderNumber}
            </a>{" "}
            {order.shippingAddress && order.shippingAddress.lastName}{" "}
            {order.shippingAddress && order.shippingAddress.firstName}
          </div>
          <div className="pb-1">
            {t("Total")} :{" "}
            {order.orderItems.reduce(function (a, b) {
              return a + parseFloat(b.price) * b.qty;
            }, 0)}{" "}
            MAD
          </div>
          <div className="flex justify-between flex-grow">
            <div className="opacity-70 pr-4 flex items-center">
              <div
                className={`h-2 w-2 rounded-full ${
                  order.isOffline ? "bg-red" : "bg-green-600"
                }  `}
              ></div>
              <div className="pl-3">
                {t("Order at")} :{order.createdAt.substring(0, 10)}
              </div>
            </div>
            <div className="opacity-70 flex">
              {order.isPaid && (
                <div>
                  | {t("Paid at")}{" "}
                  {order.isPaid && order.paidAt.substring(0, 10)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="cursor-pointer relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setIsOpen(!isOpen)}
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>

        <div
          ref={ref}
          className={`p-2 ${
            isOpen ? "block" : "hidden"
          } absolute bg-white shadow-md border z-50 right-0`}
        >
          <button
            onClick={() => history.push(`/admin/orderlist/${order._id}/`)}
            className="whitespace-nowrap  hover:bg-gray-50 py-2 px-4"
          >
            {t("Order Details")}
          </button>

          <a
            href={`mailto:${order.user.email}`}
            className="whitespace-nowrap  hover:bg-gray-50 py-2 px-4"
          >
            {t("Send Email")}
          </a>

          <hr />
          <button
            onClick={deleteHandler(order._id)}
            className="whitespace-nowrap  hover:bg-gray-50 py-2 px-4"
          >
            Delete Order
          </button>
        </div>
      </div>
    </div>
  );
};

const OfflineOrderListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const order_choices = [
    { id: 1, name: "All", value: "All" },
    { id: 2, name: "Online", value: "False" },
    { id: 3, name: "Offline", value: "True" },
  ];

  const [orderStatus, setOrderStatus] = useState();

  useEffect(() => {
    if (userInfo) {
      dispatch(listOrders(orderStatus));
    } else {
      history.push("/login");
    }
  }, [dispatch, history, userInfo, orderStatus]);

  const ExportData = () => {
    try {
      axiosInstance
        .get(`/orders/export/`, {
          responseType: "blob",
        })
        .then((response) => {
          let url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `order-01-023-filename.csv`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {}
  };

  return (
    <>
      <AdminLayout>
        <div className=" w-full ">
          <div className="w-full pb-8 flex justify-between items-center ">
            <h1 className="text-xl ">Orders</h1>

            <div className="flex items-center gap-x-3 ">
              <div className="flex items-center gap-3">
                {order_choices.map((or_ch) => (
                  <div key={or_ch.id}>
                    <input
                      type="radio"
                      onChange={(e) => setOrderStatus(e.target.value)}
                      name="status"
                      id="status"
                      value={or_ch.value}
                    />{" "}
                    {or_ch.name}
                  </div>
                ))}
              </div>
              <button
                onClick={ExportData}
                className="bg-secondary flex items-center rounded-full px-4 py-2 text-white "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
                <span className="pl-2">Download</span>
              </button>
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <div className="w-full">
              {orders.map((order, id) => (
                <OrderItem order={order} key={id} />
              ))}
            </div>
          )}
        </div>
      </AdminLayout>
    </>
  );
};

export default OfflineOrderListScreen;
