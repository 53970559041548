import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import UserListScreen from "./screens/admin/UserListScreen";
import UserEditScreen from "./screens/admin/UserEditScreen";
import ProductListScreen from "./screens/admin/ProductListScreen";
import OrderListScreen from "./screens/admin/OrderListScreen";
import ProductEditScreen from "./screens/admin/ProductEditScreen";
import Dashboard from "./screens/admin/Dashboard";
import OrganizerListAdmin from "./screens/admin/OrganizerListAdmin";
import OrganizerEditScreen from "./screens/admin/OrganizerEditScreen";
import OrderDetailAdminScreen from "./screens/admin/OrderDetailAdminScreen";
import OrderAddOfflineScreen from "./screens/admin/OrderAddOfflineScreen";
import DiscountsScreen from "./screens/admin/DiscountsScreen";
import OfflineOrderListScreen from "./screens/admin/OfflineOrderListScreen";
import LoginScreen from "./screens/LoginScreen";
import NewDiscountScreen from "./screens/admin/NewDiscountScreen";
import ProductNewScreen from "./screens/admin/ProductNewScreen";
import Tickets from "./screens/admin/Tickets";
import ProductEditScreen2 from "./screens/admin/ProductEditScreen2";
import DonationsScreen from "./screens/admin/DonationsScreen";

import "./i18n.js";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
}

const App = () => {
  return (
    <div className="">
      <Router>
        <ScrollToTop />

        <main className="min-h-screen">
          <>
            <Route path="/" component={Dashboard} exact />
            <Route path="/login" component={LoginScreen} exact />

            <Route path="/userlist" component={UserListScreen} />
            <Route path="/user/:id/edit" component={UserEditScreen} />

            <Route path="/productlist" component={ProductListScreen} exact />
            <Route
              path="/productlist/page/:pageNumber"
              component={ProductListScreen}
              exact
            />
            <Route path="/product/:id/edit" component={ProductEditScreen2} />
            <Route path="/product/new" component={ProductNewScreen} />
            <Route path="/orderlist" exact component={OrderListScreen} />
            <Route
              path="/off-orderlist"
              exact
              component={OfflineOrderListScreen}
            />
            <Route
              path="/add-offline-order"
              exact
              component={OrderAddOfflineScreen}
            />
            <Route path="/discounts" exact component={DiscountsScreen} />
            <Route path="/donations" exact component={DonationsScreen} />
            <Route path="/discounts/new" exact component={NewDiscountScreen} />
            <Route path="/tickets" exact component={Tickets} />

            <Route
              path="/orderlist/page/:pageNumber"
              exact
              component={OrderListScreen}
            />
            <Route path="/tickets/page/:pageNumber" exact component={Tickets} />

            <Route
              path="/orderlist/:id"
              exact
              component={OrderDetailAdminScreen}
            />

            <Route path="/organizerslist" component={OrganizerListAdmin} />
            <Route path="/organizer/:id/edit" component={OrganizerEditScreen} />
          </>
        </main>

        {/* <Footer /> */}
      </Router>
    </div>
  );
};

export default App;
