import React, { useEffect, useState } from "react";
import axiosInstance from "../axios";
import ProgressBar from "./ProgressBar";
import { useTranslation } from "react-i18next";

function Inventory({ number = 0, organizerSelect = "" }) {
  const [inventory, setInventory] = useState({ sold: 0, total: 0 });
  useEffect(() => {
    // last
    // axiosInstance
    //   .get("/stats/-inventory/")
    //   .then((res) => {
    //     setInventory(res.data);
    //   });
    // new
    axiosInstance
      .get("/stats/new-inventory/?organizer=" + organizerSelect)
      .then((res) => {
        setInventory(res.data);
      });
  }, [organizerSelect]);
  const { t } = useTranslation();

  return (
    <div className="p-2 border w-full bg-white rounded-md">
      <h1 className="">{t("Ticket inventory")}</h1>
      <div className="py-1">
        <span className="text-2xl">{inventory.sold}</span> /{" "}
        <span className="">{inventory.total + inventory.sold}</span>
      </div>
      <ProgressBar
        number={inventory.sold / (inventory.total + inventory.sold)}
      />
    </div>
  );
}

export default Inventory;
