import React from "react";
import { Link } from "react-router-dom";

const Paginate = ({ pages, page, section, organizer = "", search = "" }) => {
  const prevPage = page - 1;
  const nextPage = page + 1;

  const showPrevButton = page > 1;
  const showNextButton = page < pages;

  const pageNumbers = [];

  if (pages <= 5) {
    // If total pages are less than or equal to 5, show all page numbers
    for (let i = 1; i <= pages; i++) {
      pageNumbers.push(i);
    }
  } else {
    // Always show the first page
    pageNumbers.push(1);

    // Show ellipsis before current page if current page is far enough from page 1
    if (page > 3) {
      pageNumbers.push("...");
    }

    // Determine the middle pages to show
    const startPage = Math.max(2, page - 1);
    const endPage = Math.min(pages - 1, page + 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Show ellipsis after current page if current page is far enough from the last page
    if (page < pages - 2) {
      pageNumbers.push("...");
    }

    // Always show the last page
    pageNumbers.push(pages);
  }

  return (
    pages > 1 && (
      <div className="flex justify-end pt-8">
        {/* Previous Button */}
        {showPrevButton && (
          <Link
            to={`/${section}/page/${prevPage}?${
              organizer !== "" ? "organizer=" + organizer + "&" : ""
            }${search !== "" ? "search=" + search + "&" : ""}page=${prevPage}`}
          >
            <div className="border p-1 w-8 mr-2 hover:bg-opacity-90 flex items-center justify-center rounded-md">
              {"<"}
            </div>
          </Link>
        )}

        {/* Page Numbers */}
        {pageNumbers.map((num, index) => {
          if (num === "...") {
            return (
              <div key={index} className="border p-1 w-8 mr-2 rounded-md">
                {num}
              </div>
            );
          }

          return (
            <Link
              key={num}
              to={`/${section}/page/${num}?${
                organizer !== "" ? "organizer=" + organizer + "&" : ""
              }${search !== "" ? "search=" + search + "&" : ""}page=${num}`}
            >
              <div
                className={`border p-1 w-8 mr-2 hover:bg-opacity-90 flex items-center justify-center rounded-md ${
                  num === page ? "bg-primary text-white" : ""
                }`}
              >
                {num}
              </div>
            </Link>
          );
        })}

        {/* Next Button */}
        {showNextButton && (
          <Link
            to={`/${section}/page/${nextPage}?${
              organizer !== "" ? "organizer=" + organizer + "&" : ""
            }${search !== "" ? "search=" + search + "&" : ""}page=${nextPage}`}
          >
            <div className="border p-1 w-8 mr-2 hover:bg-opacity-90 flex items-center justify-center rounded-md">
              {">"}
            </div>
          </Link>
        )}
      </div>
    )
  );
};

export default Paginate;
