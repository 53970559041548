import axios from "../axios";

export const listTickets =
  (page, organizer = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "TICKET_LIST_REQUEST" });

      var {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      // last
      // const { data } = await axios.get(
      //   `/tickets/scanned?page=${page}&organizer=${organizer}`,
      //   config
      // );
      // new
      const { data } = await axios.get(
        `/tickets/new-scanned/?page=${page}&organizer=${organizer}`,
        config
      );

      dispatch({
        type: "TICKET_LIST_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "TICKET_LIST_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
