import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOfflineOrder } from "../../actions/orderActions";
import { listAdminProducts } from "../../actions/productActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import AdminLayout from "../../layout/AdminLayout";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import axiosInstance from "../../axios";
import { logout } from "../../actions/userActions";

function OrderAddOfflineScreen({ history }) {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const organizer = params.get("organizer") || "";

  const [listOrganizers, setListOrganizers] = useState([]);
  const [selectOrganizer, setSelectOrganizer] = useState(organizer);

  const dispatch = useDispatch();

  const [qty, setQty] = useState(1);
  const [customization, setCustomization] = useState("");
  const [total, setTotal] = useState(0);
  const [selectedVariation, setSelectedVariation] = useState();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  const [product, setProduct] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      axiosInstance
        .get("/organizers/organizers-user/", config)
        .then((res) => {
          // console.log(res.data)
          setListOrganizers(res.data.organizers ?? []);
          if (listOrganizers.length !== 0) {
            setSelectOrganizer(listOrganizers[0]._id ?? "");
            console.log(selectOrganizer);
          } else {
            console.log("null hh");
          }
        })
        .catch((error) => {
          const message =
            error.response && error.response.data.detail
              ? error.response.data.detail
              : error.detail;

          if (message == "User not found") {
            dispatch(logout());
          }
        });
    }
  }, [userInfo, history]);

  useEffect(() => {
    if (listOrganizers.length !== 0 && selectOrganizer === "") {
      setSelectOrganizer(listOrganizers[0]._id ?? "");
    }
  }, [listOrganizers, selectOrganizer]);

  useEffect(() => {
    dispatch(listAdminProducts("", "", selectOrganizer, 0));
  }, [dispatch, selectOrganizer]);

  const handleVariationChnage = (e) => {
    const selectedId = e.target.value;
    setSelectedVariation(selectedId);
    const selectedAttribute = product.variation.attributes.find(
      (att) => att.id === parseInt(selectedId)
    );
    setTotal(parseFloat(selectedAttribute.price));
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isFree, setIsFree] = useState(true);

  const orderCreate = useSelector((state) => state.orderCreate);
  const {
    loading: loadingOrderCreate,
    order,
    success,
    errorOrderCreate,
  } = orderCreate;

  const saveData = (e) => {
    e.preventDefault();

    const t = selectedVariation
      ? product.variation.attributes?.find(
          (item) => item.id == selectedVariation
        )
      : null;

    const cartItems = [
      {
        organizer: selectOrganizer,
        customization: customization,
        countInStock: product.countInStock,
        description: product.description,
        image: product.images[0].image,
        name: product.name,
        price: isFree ? 0.0 : total > 0 ? total : product.price,
        product: product._id,
        qty: qty,
        variation_attribute_id: t ? t.id : null,
        variation_name: product?.variation?.name,
        variation_value: t ? t.name : "",
      },
    ];

    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    cartItems.itemsPrice = addDecimals(
      cartItems.reduce(
        (acc, item) => acc + parseFloat(item.price) * item.qty,
        0
      )
    );
    // cart.taxPrice = addDecimals(Number((0 * cart.itemsPrice).toFixed(2)))

    cartItems.totalPrice = Number(cartItems.itemsPrice).toFixed(2);

    dispatch(
      createOfflineOrder({
        orderItems: cartItems,
        // user: user,
        // customization: customization,
        infoClient: {
          firstName,
          lastName,
          phone,
          email,
        },
        isFree: isFree,
        paymentMethod: "CMI",
        itemsPrice: isFree ? 0.0 : cartItems.itemsPrice,
        taxPrice: 0,
        totalPrice: isFree ? 0.0 : cartItems.totalPrice,
        organizer: selectOrganizer,
      })
    );
  };

  const { t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams();

    if (selectOrganizer) {
      params.set("organizer", selectOrganizer);
    } else {
      params.delete("organizer");
    }

    // Update the URL with new query params
    history.push({
      pathname: "/add-offline-order",
      search: params.toString(),
    });
  }, [selectOrganizer, history]);

  return (
    <AdminLayout>
      <div className="flex pb-10">
        <div className="w-full md:w-2/3 ">
          <h1 className="text-xl font-semibold">{t("Add offline order")} </h1>
          {success && (
            <div className="py-2 text-green-600">
              {t("Offline Order has been created !")}
            </div>
          )}
          <div className="pt-3">
            <form method="post" onSubmit={saveData}>
              <div className="my-2 p-4 bg-white border rounded-md">
                {/* <h1 className="pb-2"> {t("Organizer")} </h1>
                <div className="border rounded-full">
                  <select
                    onChange={(v) => {
                      setProduct(null);
                      setSelectOrganizer(v.target.value);
                    }}
                    className=" w-full p-3  border-none outline-none bg-transparent"
                    value={selectOrganizer}
                  >
                    {listOrganizers?.map((item, index) => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </select>
                </div> */}
                <h1 className="pb-2 pt-4"> {t("Products")} </h1>
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Message variant="danger">{error}</Message>
                ) : (
                  <div className="">
                    <div className="border rounded-full">
                      <select
                        required
                        name="product"
                        onChange={(e) => {
                          setProduct({});
                          setTotal(0);
                          setProduct(JSON.parse(e.target.value));
                        }}
                        id="product"
                        className=" w-full p-3  border-none outline-none bg-transparent"
                      >
                        <option value="">{t("Choose product")}</option>
                        {products.map((product) => (
                          <option
                            key={product._id}
                            value={JSON.stringify(product)}
                          >
                            {product.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                {product && (
                  <>
                    {product.hasVariation &&
                      product.variation &&
                      product.variation.name && (
                        <div>
                          <label>
                            {product.variation && product.variation.name}
                          </label>

                          <div className="border rounded-full">
                            <select
                              onChange={handleVariationChnage}
                              className="w-full p-3  border-none outline-none bg-transparent"
                              value={selectedVariation}
                              required
                            >
                              <option value="">{t("Select Option")} </option>
                              {product?.variation?.attributes?.map((att) => (
                                <option key={att.id} value={att.id}>
                                  {att.name} - {att.price}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}

                    <div className="pt-4">
                      <label htmlFor="" className="opacity-70">
                        {t("Quantity")}
                      </label>
                      <div className="border rounded-full">
                        <select
                          onChange={(e) => setQty(e.target.value)}
                          className=" w-full p-3  border-none outline-none bg-transparent"
                          required
                        >
                          {[...new Array(product.countInStock)].map(
                            (op, id) => (
                              <option key={id + 1} value={id + 1}>
                                {id + 1}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>

                    {product.hasComment && (
                      <div className="pt-4">
                        <label
                          htmlFor="personalization"
                          className="opacity-70 italic text-sm"
                        >
                          {t("Personalization")} :
                        </label>
                        <div className="border rounded-md" id="personalization">
                          <textarea
                            name="personalization"
                            id="personalization"
                            value={customization}
                            onChange={(e) => setCustomization(e.target.value)}
                            className="w-full bg-transparent outline-none border-none p-2 "
                            rows="2"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Ticket Holder Info  */}

              <div className="my-2 p-4 bg-white border rounded-md">
                <h1>{t("Customer information")} </h1>
                <div className="my-3">
                  <label className="opacity-70 ">{t("First name")}</label>
                  <div className="border rounded-md mt-1">
                    <input
                      className="p-2 w-full bg-transparent outline-none  "
                      type="text"
                      placeholder={t("Enter first name")}
                      value={firstName}
                      required
                      onChange={(e) =>
                        setFirstName(
                          e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
                        )
                      }
                    />
                  </div>
                  {/*  */}
                </div>
                <div className="my-3">
                  <label className="opacity-70 ">{t("Last name")}</label>
                  <div className="border rounded-md mt-1">
                    <input
                      className="p-2 w-full outline-none bg-transparent "
                      type="text"
                      placeholder={t("Enter last name")}
                      value={lastName}
                      required
                      onChange={(e) =>
                        setLastName(
                          e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
                        )
                      }
                    />
                  </div>
                </div>

                <div className="my-2">
                  <label className="opacity-70 pb-1">{t("Phone number")}</label>
                  <div className="border rounded-md mt-1">
                    <input
                      className="p-2 w-full  outline-none bg-transparent "
                      type="tel"
                      pattern="[0-9]*\.?[0-9]*"
                      placeholder={t("Enter phone number")}
                      value={phone}
                      required
                      onChange={(e) =>
                        setPhone(e.target.value.replace(/[^0-9]/g, ""))
                      }
                    />
                  </div>
                </div>

                <div className="my-2">
                  <label className="opacity-70 pb-2">{t("Email")}</label>
                  <div className="border rounded-md mt-1">
                    <input
                      className="p-2 w-full outline-none"
                      type="email"
                      placeholder={t("Enter email")}
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {/* Payment : Free / with Discount / Same price  */}

              {/* <div className="my-2 bg-white p-4 border rounded-md">
                <h1>{t("Payment")}</h1>

                <div className="py-3">
                  {t("Total amount to be paid")}:{" "}
                  <b>{isFree ? 0.0 : total > 0 ? total : product?.price}</b> MAD
                </div>
                <div className="flex items-center gap-3">
                  <div className="cr-input border rounded-md p-2">
                    <label htmlFor="paid" className="pr-2">
                      {t("Paid")}
                    </label>
                    <input
                      id="paid"
                      type="radio"
                      className="peer"
                      name="payment-paye"
                      value={false}
                      checked={isFree == false}
                      onChange={(e) => setIsFree(false)}
                    />
                  </div>
                  <div className="cr-input border rounded-md p-2">
                    <label htmlFor="free" className="pr-2">
                      {t("Free")}
                    </label>
                    <input
                      id="free"
                      type="radio"
                      name="payment-free"
                      value={true}
                      checked={isFree == true}
                      onChange={(e) => setIsFree(true)}
                    />
                  </div>
                </div>
              </div> */}

              <div className="py-4 flex justify-end">
                <button
                  type="submit"
                  disabled={loadingOrderCreate}
                  className="bg-black rounded-md text-white px-8 py-2 "
                >
                  {loadingOrderCreate ? "..loading" : t("Save")}
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* <div className="md:w-1/3 p-2 ">
          <h1 className="text-xl font-semibold">Ordres précédents</h1>
          <div className="pt-3">
            <div className="my-2 p-4 bg-white border rounded-md">
              Pas encore de commandes offline
            </div>
          </div>
        </div> */}
      </div>
    </AdminLayout>
  );
}

export default OrderAddOfflineScreen;
