import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Paginate from "../../components/Paginate";
import {
  deleteProduct,
  disableProduct,
  listAdminProducts,
} from "../../actions/productActions";
import { PRODUCT_CREATE_RESET } from "../../constants/productConstants";
import getFormatedPrice from "../../utils/getFormatedPrice";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useHistory } from "react-router-dom";
import AdminLayout from "../../layout/AdminLayout";
import Sections from "../../components/Sections";
import Confirm from "../../components/Confirm";
import { useTranslation } from "react-i18next";
import axiosInstance, { baseURL, baseURLFiles } from "../../axios";
import { logout } from "../../actions/userActions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Item = ({ product, organizerSelect = "" }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openActiveConfirm, setOpenActiveConfirm] = useState(false);

  useOnClickOutside(ref, () => setIsOpen(false));

  const deleteHandler = (id) => (ev) => {
    // last
    // dispatch(deleteProduct(id));
    // new
    dispatch(deleteProduct(id, organizerSelect));
  };

  const handleDisableProduct = (id, isActive) => (ev) => {
    dispatch(disableProduct(id, isActive));
  };
  const { t } = useTranslation();

  return (
    <div className="flex justify-between mb-2 bg-white w-full py-2 px-4 rounded-xl shadow-md border">
      <div className="flex">
        <div className="w-28 h-28 flex-none mr-3">
          <img
            src={
              baseURL === "/api/"
                ? product?.images[0]?.image
                : baseURLFiles + product?.images[0]?.image
            }
            className="rounded-md w-full object-cover h-full"
            alt=""
          />
        </div>
        <div className="pl-2 flex-wrap">
          <div className="text-lg ">{product.name}</div>
          <div className="font-medium">{getFormatedPrice(product.price)}</div>
          <div className="text-sm">
            {" "}
            {product?.countInStock > 0 ? (
              `${t("Quantity")} : ${product?.countInStock}`
            ) : (
              <span className="bg-red-600 text-white rounded-md px-1">
                {t("Sold Out")}
              </span>
            )}
          </div>

          <div className="text-sm py-1 opacity-70">
            {product.isActive ? (
              <span className="px-1 rounded-md bg-green-600 text-white">
                {t("Active")}
              </span>
            ) : (
              <span className="px-1 rounded-md text-white bg-red-600">
                {t("Deactivated")}
              </span>
            )}
          </div>
          <div className="text-sm text-red">
            {product?.hasActiveDiscount ? "ON SALE" : ""}
          </div>
        </div>
      </div>

      <div className="cursor-pointer relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setIsOpen(!isOpen)}
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>

        <div
          ref={ref}
          className={`p-2 ${
            isOpen ? "block" : "hidden"
          } absolute bg-white shadow-md border z-50 right-0`}
        >
          <button
            onClick={() =>
              history.push(
                `/product/${product._id}/edit/?organizer=${organizerSelect}`
              )
            }
            className="whitespace-nowrap hover:bg-gray-50 py-1 px-4"
          >
            {t("Edit Product")}
          </button>
          {/* <button onClick={()=> setIsOpenDiscount(true)} className='whitespace-nowrap hover:bg-gray-50 py-1 px-4'>
            Add Discount 
          </button> */}
          <button
            onClick={() => setOpenActiveConfirm(true)}
            className="whitespace-nowrap disabled:bg-gray-100 mb-2 hover:bg-gray-50 py-1 px-4"
          >
            {product.isActive ? t("Deactivate") : t("Activate")}
          </button>

          <hr />

          <button
            onClick={() => setOpenConfirm(true)}
            className="whitespace-nowrap text-red-600 hover:bg-gray-50 py-1 px-4"
          >
            {t("Delete Product")}
          </button>
        </div>
      </div>

      <Confirm
        onClose={() => setOpenActiveConfirm(false)}
        open={openActiveConfirm}
        onConfirm={handleDisableProduct(product._id, !product.isActive)}
      >
        {t("Are you sure you want to confirm this action !")}
      </Confirm>

      <Confirm
        onClose={() => setOpenConfirm(false)}
        open={openConfirm}
        onConfirm={deleteHandler(product._id)}
      >
        {t("Are you sure you want to confirm this action !")}
      </Confirm>
    </div>
  );
};

const ProductListScreen = ({ history, match }) => {
  const { t } = useTranslation();

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const organizer = params.get("organizer") || "";

  const pageNumber = params.get("page") || "1";

  const [listOrganizers, setListOrganizers] = useState([]);
  const [selectOrganizer, setSelectOrganizer] = useState(organizer);

  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });

    if (!userInfo) {
      history.push("/login");
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      axiosInstance
        .get("/organizers/organizers-user/", config)
        .then((res) => {
          // console.log(res.data)
          setListOrganizers(res.data.organizers ?? []);
          if (listOrganizers.length !== 0) {
            setSelectOrganizer(listOrganizers[0]._id ?? "");
            console.log(selectOrganizer);
          } else {
            console.log("null hh");
          }
        })
        .catch((error) => {
          const message =
            error.response && error.response.data.detail
              ? error.response.data.detail
              : error.detail;

          if (message == "User not found") {
            dispatch(logout());
          }
        });
    }

    if (successCreate) {
      history.push(`/product/${createdProduct._id}/edit`);
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
  ]);

  useEffect(() => {
    const params = new URLSearchParams();

    if (selectOrganizer) {
      params.set("organizer", selectOrganizer);
    } else {
      params.delete("organizer");
    }

    if (pageNumber) {
      params.set("page", pageNumber);
    } else {
      params.delete("page");
    }

    // Update the URL with new query params
    history.push({
      pathname: "/productlist",
      search: params.toString(),
    });
  }, [selectOrganizer, history]);

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });

    if (!userInfo) {
      history.push("/login");
    } else {
      dispatch(listAdminProducts("", "", selectOrganizer, pageNumber));
    }
  }, [dispatch, history, userInfo, pageNumber, selectOrganizer]);

  useEffect(() => {
    dispatch(listAdminProducts("", "", selectOrganizer, "1"));
  }, [dispatch, history, successDelete]);

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });

    if (!userInfo) {
      history.push("/login");
    } else if (successCreate) {
      history.push(`/product/${createdProduct._id}/edit`);
    }
  }, [dispatch, history, userInfo, successCreate, createdProduct]);

  const createProductHandler = () => {
    // dispatch(createProduct());
    document.location.href = "/product/new?organizer=" + selectOrganizer;
  };

  useEffect(() => {
    if (listOrganizers.length !== 0 && selectOrganizer === "") {
      setSelectOrganizer(listOrganizers[0]._id ?? "");
    }
  }, [listOrganizers, selectOrganizer]);

  return (
    <AdminLayout>
      <div className="flex w-full  ">
        <div className=" w-full">
          <div className="pb-4 text-xl">{t("Sections & Products")}</div>
          {/* <div className="my-3">
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-black font-bold text-sm  mb-1">
                  Organizer
                </div>
                <div>
                  <select
                    onChange={(v) => setSelectOrganizer(v.target.value)}
                    className={` outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm`}
                    value={selectOrganizer}
                  >
                    {listOrganizers?.map((item, index) => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div> */}
          <div className="pb-6">
            <Sections organizerSelect={selectOrganizer} />
          </div>

          {loadingDelete && <Loader />}
          {errorDelete && <Message variant="danger">{errorDelete}</Message>}
          {loadingCreate && <Loader />}
          {errorCreate && <Message variant="danger">{errorCreate}</Message>}

          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <>
              {products?.map((product) => (
                <Item
                  key={product._id}
                  organizerSelect={selectOrganizer}
                  product={product}
                />
              ))}

              <Paginate
                section={"productlist"}
                pages={pages}
                page={page}
                organizer={selectOrganizer}
              />
            </>
          )}
          <div className="pt-3 flex items-center justify-end">
            <button
              onClick={createProductHandler}
              className="bg-primary rounded-full p-3 px-6  text-white"
            >
              {t("New Product")}
            </button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ProductListScreen;
