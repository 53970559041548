import React from "react";

function ProgressBar({ number = 0.75 }) {
  return (
    <div class="w-full bg-primary rounded-full h-2.5 ">
      <div
        class="bg-secondary h-2.5 rounded-full"
        style={{ width: number * 100 + "%" }}
      ></div>
    </div>
  );
}

export default ProgressBar;
