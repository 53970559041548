import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductOverview from "../../components/ProductOverview";
import SalesAnalytics from "../../components/SalesAnalytics";
import Stats from "../../components/Stats";
import AdminLayout from "../../layout/AdminLayout";
import Inventory from "../../components/Inventory";
import DaysLeft from "../../components/DaysLeft";

import Calendar from "react-calendar";
import "../../style/Calendar.css";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axios";
import { logout } from "../../actions/userActions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function Dashboard({ history }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const organizer = params.get("organizer") || "";

  const [listOrganizers, setListOrganizers] = useState([]);
  const [selectOrganizer, setSelectOrganizer] = useState(organizer);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      axiosInstance
        .get("/organizers/organizers-user/", config)
        .then((res) => {
          // console.log(res.data)
          setListOrganizers(res.data.organizers ?? []);

          if (listOrganizers.length !== 0) {
            setSelectOrganizer(listOrganizers[0]._id ?? "");
            console.log(selectOrganizer);
          } else {
            console.log("null hh");
          }
        })
        .catch((error) => {
          const message =
            error.response && error.response.data.detail
              ? error.response.data.detail
              : error.detail;
          console.log(message);

          if (
            message == "User not found" ||
            message === "Informations d'authentification non fournies."
          ) {
            dispatch(logout());
          }
        });
    }
  }, [userInfo, history]);

  useEffect(() => {
    console.log(organizer);
  }, [organizer]);

  useEffect(() => {
    if (listOrganizers.length !== 0 && selectOrganizer === "") {
      setSelectOrganizer(listOrganizers[0]._id ?? "");
    }
  }, [listOrganizers, selectOrganizer]);

  useEffect(() => {
    const params = new URLSearchParams();

    if (selectOrganizer) {
      params.set("organizer", selectOrganizer);
    } else {
      params.delete("organizer");
    }

    // Update the URL with new query params
    history.push({
      pathname: "/",
      search: params.toString(),
    });
  }, [selectOrganizer, history]);

  return (
    <AdminLayout>
      <div className="pb-2">
        <h1 className="tetx-xl ">{t("Dashboard")}</h1>
      </div>
      {/* <div className="my-3">
        <div className="flex md:flex-row flex-col  ">
          <div className="md:w-1/2 w-full  md:pr-1 my-1">
            <div className="text-black font-bold text-sm  mb-1">
              {t("Organizer")}
            </div>
            <div>
              <select
                onChange={(v) => setSelectOrganizer(v.target.value)}
                className={` outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm`}
                value={selectOrganizer}
              >
                {listOrganizers?.map((item, index) => (
                  <option value={item._id}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div> */}
      <Stats organizerSelect={selectOrganizer} />

      <div className="py-4">
        <h1>{t("Stats")}</h1>
        <div className="pt-3 grid md:grid-cols-5 gap-3">
          <div className="md:col-span-3 bg-white rounded-md">
            <SalesAnalytics organizerSelect={selectOrganizer} />
          </div>
          <div className=" md:col-span-2">
            <div className="grid grid-cols-2 md:grid-cols-1 gap-3 ">
              <div className="">
                <Calendar
                  locale={i18n.language}
                  showNavigation={false}
                  view="month"
                  // tileClassName={colorRange}
                  className={"p-2 w-full border-none rounded-md "}
                />
              </div>
              <div className="pt-2  ">
                <Inventory number={10} organizerSelect={selectOrganizer} />
                <DaysLeft organizerSelect={selectOrganizer} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProductOverview organizerSelect={selectOrganizer} />

      {/* <Confirm onConfirm={()=>alert("ok")} open={true}  >
                Vous êtes sûr de supprimer ce Control ! 
            </Confirm> */}
    </AdminLayout>
  );
}

export default Dashboard;
