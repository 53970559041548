import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disableCoupon, listCoupon } from "../../actions/couponActions";
import AdminLayout from "../../layout/AdminLayout";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axios";
import { logout } from "../../actions/userActions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function DiscountsScreen({ history }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { coupons, error, loading } = useSelector((state) => state.couponList);
  const params = new URLSearchParams(location.search);
  const organizer = params.get("organizer") || "";

  const [listOrganizers, setListOrganizers] = useState([]);
  const [selectOrganizer, setSelectOrganizer] = useState(organizer ?? "");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      // dispatch(listCoupon());
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      axiosInstance
        .get("/organizers/organizers-user/", config)
        .then((res) => {
          // console.log(res.data)
          setListOrganizers(res.data.organizers ?? []);
          if (listOrganizers.length !== 0) {
            setSelectOrganizer(listOrganizers[0]._id ?? "");
            console.log(selectOrganizer);
          } else {
            console.log("null hh");
          }
        })
        .catch((error) => {
          const message =
            error.response && error.response.data.detail
              ? error.response.data.detail
              : error.detail;

          if (message == "User not found") {
            dispatch(logout());
          }
        });
    }
  }, [userInfo, history, dispatch]);

  useEffect(() => {
    if (listOrganizers.length !== 0 && selectOrganizer === "") {
      setSelectOrganizer(listOrganizers[0]._id ?? "");
    }
  }, [listOrganizers, selectOrganizer]);

  useEffect(() => {
    if (selectOrganizer !== "") {
      dispatch(listCoupon(selectOrganizer));
    }
  }, [dispatch, selectOrganizer]);

  const showCouponAddModal = () => {
    setIsOpen(true);
  };

  const handleDisableCoupon = (id) => {
    dispatch(disableCoupon(id));

    document.location.href = "/discounts/";
  };
  const { t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams();

    if (selectOrganizer) {
      params.set("organizer", selectOrganizer);
    } else {
      params.delete("organizer");
    }

    // Update the URL with new query params
    history.push({
      pathname: "/discounts",
      search: params.toString(),
    });
  }, [selectOrganizer, history]);

  return (
    <AdminLayout>
      <div className="flex justify-end">
        <a
          href={"/discounts/new?organizer=" + selectOrganizer}
          className="bg-primary rounded-full px-4 text-white p-2"
        >
          {t("Add Coupon Code")}
          {/* <button
          className="bg-primary rounded-full px-4 text-white p-2"
          onClick={() => alert("coming soon !")}
        >
          Add Coupon Code
        </button> */}
        </a>
      </div>
      {/* <div className="my-3">
        <div className="flex md:flex-row flex-col  ">
          <div className="md:w-1/2 w-full  md:pr-1 my-1">
            <div className="text-black font-bold text-sm  mb-1">
              {t("Organizer")}
            </div>
            <div>
              <select
                onChange={(v) => setSelectOrganizer(v.target.value)}
                className={` outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm`}
                value={selectOrganizer}
              >
                {listOrganizers?.map((item, index) => (
                  <option value={item._id}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div> */}
      <div className="w-full mt-4 px-4 bg-white rounded-md flex justify-between max-w-full overflow-x-auto">
        <table className="w-full table-auto  ">
          <thead className="">
            <tr className="h-12 justify-start">
              <th className="text-start w-max px-2">{t("Offer")}</th>
              <th className="text-start w-max px-2">{t("Product")}</th>
              <th className="text-start w-max px-2">{t("Discount")}</th>
              <th className="text-start w-max px-2">{t("Duration")}</th>
              <th className="text-start w-max px-2">{t("Used")}</th>
              <th className="text-start w-max px-2">{t("Is valid")}</th>
            </tr>
          </thead>
          <tbody>
            {loading
              ? "Loading..."
              : error
              ? error
              : coupons?.map((coup) => (
                  <tr className="border-b h-12" key={coup.id}>
                    <td className="w-max px-2">{coup.code}</td>
                    <td className="w-max px-2">
                      {coup.product
                        ? coup.product?.length > 20
                          ? coup.product?.substring(0, 20) + "..."
                          : coup.product
                        : "All Products"}
                    </td>

                    <td className="w-max px-2">{coup.discount}%</td>
                    <td className="w-max px-2">
                      {coup.duration < 0
                        ? t("Expired")
                        : coup.duration + " days"}
                    </td>
                    <td className="w-max px-2">
                      {coup.has_max
                        ? `${coup.used}/${coup.max_count}`
                        : coup.used}
                    </td>
                    <td className="w-max px-2">
                      {coup.is_valid ? "Valid" : "Not Valid"}
                    </td>

                    <td className="w-max px-2">
                      {coup.is_valid && (
                        <button
                          className="border rounded-full bg-gray-100 px-2 opacity-70 hover:opacity-100"
                          onClick={() => handleDisableCoupon(coup.id)}
                        >
                          Désactiver
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      {/* <AddCouponDiscount isOpen={isOpen} setIsOpen={setIsOpen} /> */}
    </AdminLayout>
  );
}

export default DiscountsScreen;
