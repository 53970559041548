import React from "react";
import AdminLayout from "../../layout/AdminLayout";
import axiosInstance from "../../axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paginate from "../../components/Paginate";
import { listTickets } from "../../actions/ticketActions";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { logout } from "../../actions/userActions";

const CardItem = ({ title, number, icon }) => {
  return (
    <div className="border card-bg text-white rounded-xl px-4 py-3">
      <div className="flex items-center gap-3 text-xs">
        {icon} {title}
      </div>
      <div className="py-2 text-2xl font-extrabold">{number}</div>
    </div>
  );
};

function Tickets({ match, history }) {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const organizer = params.get("organizer") || "";
  const pageNumber = params.get("page") || 1;

  const [listOrganizers, setListOrganizers] = useState([]);
  const [selectOrganizer, setSelectOrganizer] = useState(organizer);

  const dispatch = useDispatch();
  const [data, setData] = useState({
    tickets_scanned: 0,
    tickets_not_scanned: 0,
  });

  // const pageNumber = match.params.pageNumber || 1;

  const userLogin = useSelector((state) => state.userLogin);
  const { tickets, page, pages } = useSelector((state) => state.ticketList);
  const { userInfo } = userLogin;

  // last
  // const config = {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${userInfo.access}`,
  //   },
  // };

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      // last
      // axiosInstance
      //   .get("/tickets", config)
      //   .then((res) => {
      //     console.log(res.data);
      //     setData(res.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // new
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      axiosInstance
        .get("/organizers/organizers-user/", config)
        .then((res) => {
          // console.log(res.data)
          setListOrganizers(res.data.organizers ?? []);
          if (listOrganizers.length !== 0) {
            setSelectOrganizer(listOrganizers[0]._id ?? "");
            console.log(selectOrganizer);
          } else {
            console.log("null hh");
          }
        })
        .catch((error) => {
          const message =
            error.response && error.response.data.detail
              ? error.response.data.detail
              : error.detail;

          if (message == "User not found") {
            dispatch(logout());
          }
        });
    }
  }, [userInfo, history]);

  useEffect(() => {
    if (selectOrganizer !== "") {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      axiosInstance
        .get("/tickets/new-data/?organizer=" + selectOrganizer, config)
        .then((res) => {
          console.log(res.data);
          setData(res.data);
        })
        .catch((error) => {
          console.log(error);
          setData({ tickets_scanned: 0, tickets_not_scanned: 0 });
        });
    }
  }, [selectOrganizer]);

  useEffect(() => {
    // dispatch(listTickets(pageNumber));
    dispatch(listTickets(pageNumber, selectOrganizer));
  }, [dispatch, pageNumber, selectOrganizer]);

  // useEffect(() => {
  //   axiosInstance
  //     .get(`/tickets/scanned?page=${pageNumber}`, config)
  //     .then((res) => {
  //       // console.log(res.data);
  //       setTickets(res.data.tickets);
  //       setPage(res.data.page);
  //       setPages(res.data.pages);
  //     })
  //     .catch((error) => {
  //       setTickets([]);
  //     });
  // }, []);
  // new
  useEffect(() => {
    if (listOrganizers.length !== 0 && selectOrganizer === "") {
      setSelectOrganizer(listOrganizers[0]._id ?? "");
    }
  }, [listOrganizers, selectOrganizer]);

  useEffect(() => {
    const params = new URLSearchParams();

    if (selectOrganizer) {
      params.set("organizer", selectOrganizer);
    } else {
      params.delete("organizer");
    }

    if (pageNumber) {
      params.set("page", pageNumber);
    } else {
      params.delete("page");
    }

    // Update the URL with new query params
    history.push({
      pathname: "/tickets",
      search: params.toString(),
    });
  }, [selectOrganizer, history, pageNumber]);

  //
  const { t } = useTranslation();
  return (
    <AdminLayout>
      {/* <div className="my-3">
        <div className="flex md:flex-row flex-col  ">
          <div className="md:w-1/2 w-full  md:pr-1 my-1">
            <div className="text-black font-bold text-sm  mb-1">
              {t("Organizer")}
            </div>
            <div>
              <select
                onChange={(v) => setSelectOrganizer(v.target.value)}
                className={` outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm`}
                value={selectOrganizer}
              >
                {listOrganizers?.map((item, index) => (
                  <option value={item._id}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div> */}
      <h1 className="text-xl pb-4">{t("Tickets")}</h1>
      <div className="grid md:grid-cols-2 gap-3">
        <CardItem number={data.tickets_scanned} title={t("Scanned tickets")} />
        <CardItem
          number={data.tickets_not_scanned}
          title={t("Unscanned tickets")}
        />
      </div>

      <div>
        <div className="py-4">
          <h1 className="">{t("History")} </h1>
        </div>
        <div class="flex flex-col bg-white rounded-md">
          <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div class="overflow-hidden">
                <table className="w-full">
                  <thead class="bg-white border-b">
                    <tr>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        {t("Ticket N°")}
                      </th>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        {t("Order N°")}
                      </th>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        {t("Client")}
                      </th>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        {t("Scanned at")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets?.length > 0 &&
                      tickets?.map((item) => (
                        <tr
                          key={item._id}
                          class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                        >
                          <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item.ticketNo}
                          </td>
                          <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            <a href={`/orderlist/${item.orderNo}`}>
                              {item.orderNo}
                            </a>
                          </td>
                          <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item.firstName} {item.lastName}
                          </td>

                          <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item.scannedAt}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {tickets?.length == 0 && (
                  <div class="text-sm col-span-3 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    Les tickets scannés apparaîtront ici !
                  </div>
                )}
                <Paginate
                  page={page}
                  pages={pages}
                  section={"tickets"}
                  organizer={selectOrganizer}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default Tickets;
