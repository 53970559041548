import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
} from "./reducers/productReducers";
import { cartReducer } from "./reducers/cartReducers";
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
} from "./reducers/userReducers";
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderListMyReducer,
  orderListReducer,
} from "./reducers/orderReducers";
import { sliderListReducer } from "./reducers/sliderReducer";
import {
  expiredOrganizerListReducer,
  organizerDetailReducer,
  organizerListReducer,
} from "./reducers/organizerReducers";
import {
  sectionCreateReducer,
  sectionListReducer,
  sectionUpdateReducer,
} from "./reducers/sectionReducers";
import { couponListReducer } from "./reducers/couponReducers";
import { ticketListReducer } from "./reducers/ticketReducers";

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  sliderList: sliderListReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,

  // section
  sectionList: sectionListReducer,
  sectionCreate: sectionCreateReducer,
  sectionUpdate: sectionUpdateReducer,

  // organizer
  organizerList: organizerListReducer,
  expiredOrganizerList: expiredOrganizerListReducer,
  organizerDetails: organizerDetailReducer,

  // coupon
  couponList: couponListReducer,

  // ticket
  ticketList: ticketListReducer,

  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

//  test if token has been expired !

// const now = Math.ceil(Date.now() / 1000);

// const exp = JSON.parse(localStorage.getItem('user')) ?  JSON.parse(localStorage.getItem('user')).exp :0

// if (exp > now ){
//   console.log("Not Expired")
// }

const userInfoFromStorage = localStorage.getItem("authTokens")
  ? JSON.parse(localStorage.getItem("authTokens"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;
