import { useTranslation } from "react-i18next";
import Dialog from "./Dialog";

export default function Confirm({ open, onClose, title, children, onConfirm }) {
  //   const { open, onClose, title, children, onConfirm } = props;
  const { t } = useTranslation();
  if (!open) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <h2 className="text-xl">{title}</h2>
      <div className="py-5">{children}</div>
      <div className="flex justify-end">
        <div className="p-1">
          <button
            onClick={() => onClose()}
            className="border-primary border text-primary px-4 py-1 rounded-md"
          >
            {t("No")}
          </button>
        </div>
        <div className="p-1">
          <button
            className="bg-primary text-white px-4 py-1 rounded-md"
            onClick={() => {
              onClose();
              onConfirm();
            }}
          >
            {t("Yes")}
          </button>
        </div>
      </div>
    </Dialog>
  );
}
