import React, { useEffect, useState } from "react";
import axiosInstance from "../axios";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
} from "recharts";
import Loader from "./Loader";
import Message from "./Message";
import { useTranslation } from "react-i18next";
import { logout } from "../actions/userActions";
import { useDispatch } from "react-redux";

function SalesAnalytics(props) {
  const dispatch = useDispatch();
  const organizerSelect = props.organizerSelect ?? "";

  const [filterDate, setFilterDate] = useState("today");
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    // last
    // axiosInstance
    //   .get(`/stats/chart/?time_filter=${filterDate}`)
    //   .then((res) => {
    //     setData(res.data);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setLoading(err);
    //     setError("Something went wrong !");
    //   });
    // new
    axiosInstance
      .get(
        `/stats/new-chart/?organizer=${organizerSelect}&time_filter=${filterDate}`
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(error);
        setError("Something went wrong !");
        const message =
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail;
        if (
          message == "User not found" ||
          message === "Informations d'authentification non fournies."
        ) {
          dispatch(logout());
        }
      });
  }, [filterDate, organizerSelect]);

  return (
    <div className=" p-2">
      <div className="py-2">{t("Choose date")} :</div>
      <div className="pb-3">
        <select
          onChange={(e) => setFilterDate(e.target.value)}
          name="date"
          id="date"
          className="bg-transparent border rounded-md outline-none p-2"
        >
          <option value="today">{t("Today")} </option>

          <option value="this_month">{t("This month")} </option>
          <option value="all_time">{t("All time")} </option>
        </select>
      </div>
      {loading && <Loader />}
      {error && <Message>{error}</Message>}

      <LineChart width={440} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="sales"
          stroke="#39C3D1"
          strokeWidth={2}
        />
        {/* <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="sales" fill="#39C3D1" /> */}
      </LineChart>
    </div>
  );
}

export default SalesAnalytics;
