import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
import axiosInstance from "../axios";
import getFormatedPrice from "../utils/getFormatedPrice";
import { useTranslation } from "react-i18next";

const CardItem = ({ title, number, icon }) => {
  return (
    <div className="border card-bg text-white rounded-xl px-4 py-3">
      <div className="flex items-center uppercase gap-3 text-xs">
        {icon} {title}{" "}
      </div>
      <div className="py-2 text-2xl font-extrabold">{number}</div>
    </div>
  );
};

function Stats(props) {
  const organizerSelect = props.organizerSelect ?? "";

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();

  const [stats, setStats] = useState({
    tickets_sold: 0,
    revenu: 0,
    total_places: 0,
    net_profit: 0,
  });

  useEffect(() => {
    if (userInfo && organizerSelect) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      // last
      // axiosInstance
      //   .get("/stats/", config)
      //   .then((res) => {
      //     // console.log(res.data)
      //     setStats(res.data);
      //   })
      //   .catch((error) => {
      //     const message =
      //       error.response && error.response.data.detail
      //         ? error.response.data.detail
      //         : error.detail;

      //     if (message == "User not found") {
      //       dispatch(logout());
      //     }
      //   });
      //  new
      axiosInstance
        .get("/stats/new-stats/?organizer=" + organizerSelect, config)
        .then((res) => {
          // console.log(res.data)
          setStats(res.data);
        })
        .catch((error) => {
          const message =
            error.response && error.response.data.detail
              ? error.response.data.detail
              : error.detail;

          if (message == "User not found") {
            dispatch(logout());
          }
        });
    }
  }, [organizerSelect]);
  const { t } = useTranslation();
  return (
    <div className="grid md:grid-cols-4 grid-cols-2 gap-2 lg:gap-8">
      <CardItem
        title={t("Tickets sold")}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            />
          </svg>
        }
        number={stats.tickets_sold}
      />
      <CardItem
        title={t("Revenu")}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
            />
          </svg>
        }
        number={`${stats.revenu + " MAD"}`}
      />
      <CardItem
        title={t("Total places")}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
            />
          </svg>
        }
        number={`${stats.total_places + stats.tickets_sold}`}
      />
      <CardItem
        title={t("Net profit")}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        }
        number={getFormatedPrice(stats.net_profit)}
      />
    </div>
  );
}

export default Stats;
