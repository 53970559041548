import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { deleteOrder, listOrders } from "../../actions/orderActions";
import { useHistory, useLocation, useSearchParams } from "react-router-dom";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import AdminLayout from "../../layout/AdminLayout";
import axiosInstance from "../../axios";
import Paginate from "../../components/Paginate";
import { useTranslation } from "react-i18next";
import { logout } from "../../actions/userActions";

const OrderItem = ({ order, organizer }) => {
  const ref = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(ref, () => setIsOpen(false));

  const deleteHandler = (id) => (ev) => {
    dispatch(deleteOrder(id));
  };

  return (
    <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <a
          className="text-blue-600"
          href={`/orderlist/${order._id}?organizer=${organizer}`}
        >
          {order._id.substring(0, 8)}...
        </a>
      </td>
      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        {order.createdAt}
      </td>
      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        {order.isOffline
          ? `${order.infoClient.firstName} ${order.infoClient.lastName} `
          : `${order.user.first_name} ${order.user.last_name} `}
      </td>
      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        {order.isOffline ? (
          <span className="text-red-600">Offline</span>
        ) : (
          <span className="text-green-600">Online</span>
        )}
      </td>

      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        {order.orderItems.reduce(function (a, b) {
          return a + parseFloat(b.price) * b.qty;
        }, 0)}{" "}
        MAD{" "}
        <span className="text-xs ">
          {order.hasDiscount && `(- ${order.discount} %)`}
        </span>
      </td>
      <td class="text-sm relative text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        °°°
      </td>
    </tr>
  );
};

const OrderListScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const organizer = params.get("organizer") || "";
  const search = params.get("search") || "";
  const [searchInput, setSearchInput] = useState(search);
  const pageNumber = params.get("page") || "1";

  const [listOrganizers, setListOrganizers] = useState([]);
  const [selectOrganizer, setSelectOrganizer] = useState(organizer ?? "");

  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders, page, pages, count } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const order_choices = [
    { id: 1, name: "All", value: "All" },
    { id: 2, name: "Online", value: "False" },
    { id: 3, name: "Offline", value: "True" },
  ];

  const [orderStatus, setOrderStatus] = useState("All");

  useEffect(() => {
    if (userInfo) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      axiosInstance
        .get("/organizers/organizers-user/", config)
        .then((res) => {
          // console.log(res.data)
          setListOrganizers(res.data.organizers ?? []);
          if (listOrganizers.length !== 0 && selectOrganizer === "") {
            setSelectOrganizer(listOrganizers[0]._id ?? "");
            console.log(selectOrganizer);
          } else {
            console.log("null hh");
          }
        })
        .catch((error) => {
          const message =
            error.response && error.response.data.detail
              ? error.response.data.detail
              : error.detail;

          if (message == "User not found") {
            dispatch(logout());
          }
        });
      // dispatch(listOrders(search, orderStatus, pageNumber, selectOrganizer));
    } else {
      history.push("/login");
    }
  }, [dispatch, history, userInfo, orderStatus, pageNumber, selectOrganizer]);

  const ExportData = () => {
    try {
      axiosInstance
        .get(`/orders/new-export/?organizer=${selectOrganizer}`, {
          responseType: "blob",
        })
        .then((response) => {
          let url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `order-01-023-filename.csv`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {}
  };

  const handleSearch = (e) => {
    e.preventDefault();
    window.location.href =
      "/orderlist/?search=" + searchInput + "&organizer=" + selectOrganizer;
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (listOrganizers.length !== 0 && selectOrganizer === "") {
      setSelectOrganizer(listOrganizers[0]._id ?? "");
    }
  }, [listOrganizers, selectOrganizer, organizer, search]);

  useEffect(() => {
    dispatch(listOrders(search, orderStatus, pageNumber, selectOrganizer));
  }, [search, orderStatus, pageNumber, selectOrganizer]);

  useEffect(() => {
    const params = new URLSearchParams();

    if (selectOrganizer) {
      params.set("organizer", selectOrganizer);
    } else {
      params.delete("organizer");
    }
    if (search) {
      params.set("search", search);
    } else {
      params.delete("search");
    }
    if (pageNumber) {
      params.set("page", pageNumber);
    } else {
      params.set("page", 1);
    }

    // Update the URL with new query params
    history.push({
      pathname: "/orderlist",
      search: params.toString(),
    });
    // navigate({
    //   pathname: "/orderlist",
    //   search: params.toString(),
    // });
  }, [selectOrganizer, history, search, pageNumber]);

  return (
    <>
      <AdminLayout>
        {loading ? (
          "loading ..."
        ) : error ? (
          error
        ) : (
          <div className=" w-full ">
            <div className="w-full pb-8 md:flex justify-between items-center ">
              <h1 className="text-xl pb-2">
                {t("Orders")} ({count})
              </h1>

              <div className="flex items-center gap-x-3 ">
                <div className="grid grid-cols-3 gap-3">
                  {order_choices.map((or_ch) => (
                    <div
                      className={` cursor-pointer  border rounded-full px-3 py-1 ${
                        or_ch.value == orderStatus
                          ? "bg-primary text-white"
                          : "bg-white"
                      }  `}
                      key={or_ch.id}
                    >
                      <div onClick={(e) => setOrderStatus(or_ch.value)}>
                        {t(or_ch.name)}
                      </div>
                    </div>
                  ))}
                </div>

                <button
                  onClick={ExportData}
                  className="bg-secondary flex items-center rounded-full px-4 py-1 text-white "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                  <span className="pl-2">{t("Download")}</span>
                </button>
              </div>
            </div>
            <div className="flex md:flex-row  md:justify-between md:items-center">
              {/* <div className="my-3 md:w-1/2 w-full">
                <div className="flex md:flex-row flex-col  ">
                  <div className="md:w-1/2 w-full  md:pr-1 my-1">
                    <div className="text-black font-bold text-sm  mb-1">
                      {t("Organizer")}
                    </div>
                    <div>
                      <select
                        onChange={(v) => setSelectOrganizer(v.target.value)}
                        className={` outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm`}
                        value={selectOrganizer}
                      >
                        {listOrganizers?.map((item, index) => (
                          <option value={item._id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div> */}
              {/*  */}
              <form className="md:w-1/2 w-full">
                <div className="flex justify-end">
                  <div className="border bg-white rounded-l-md ">
                    <input
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      className="p-1 w-full outline-none bg-transparent"
                      type="text"
                      placeholder={t("Search by order id or customer name")}
                    />
                  </div>
                  <button
                    onClick={handleSearch}
                    className="px-2 rounded-r-md bg-primary text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </button>
                </div>
              </form>
            </div>

            <div class="flex flex-col bg-white rounded-md mt-4">
              <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="overflow-hidden">
                    <table class="min-w-full">
                      <thead class="bg-white border-b">
                        <tr>
                          <th
                            scope="col"
                            class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            {t("Date")}
                          </th>
                          <th
                            scope="col"
                            class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            {t("Client")}
                          </th>
                          <th
                            scope="col"
                            class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            {t("Status")}
                          </th>
                          <th
                            scope="col"
                            class="text-sm whitespace-nowrap font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            {t("Total price")}
                          </th>
                          <th
                            scope="col"
                            class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <Loader />
                        ) : error ? (
                          <Message variant="danger">{error}</Message>
                        ) : (
                          <>
                            {orders?.map((order, id) => (
                              <OrderItem
                                order={order}
                                key={id}
                                organizer={selectOrganizer}
                              />
                            ))}
                          </>
                        )}

                        <tr className="h-16"></tr>
                      </tbody>
                    </table>
                    <Paginate
                      section={"orderlist"}
                      pages={pages}
                      page={page}
                      organizer={selectOrganizer}
                      search={search}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AdminLayout>
    </>
  );
};

export default OrderListScreen;
