import React from 'react'
import AdminLayout from '../../layout/AdminLayout'

function OrganizerEditScreen() {
  return (
    <AdminLayout>
        <div>
            <h1>Organizer Edit Screen</h1>
            <div className='pt-4'>
                
            </div>
        </div>
    </AdminLayout>
   
  )
}

export default OrganizerEditScreen