import React from "react";
import AdminLayout from "../../layout/AdminLayout";
import { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../../axios";

function DonationsScreen() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [donations, setDonations] = useState([]);
  useEffect(() => {
    axiosInstance
      .get("donation/")
      .then((res) => {
        setDonations(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <AdminLayout>
      <div className="pb-2">
        <h1 className="tetx-xl ">Donations</h1>
      </div>
      <div className="w-full mt-4 px-4 bg-white rounded-md flex justify-between">
        <table className="table-fixed table w-full">
          <thead className="">
            <tr className="h-12 justify-start">
              <th className="text-start">FULL NAME</th>
              <th className="text-start">EMAIL</th>
              <th className="text-start">PHONE</th>
              <th className="text-start">AMOUNT</th>
              {/* <th className="text-start"></th> */}
            </tr>
          </thead>
          <tbody>
            {loading
              ? "Loading..."
              : error
              ? error
              : donations.map((don) => (
                  <tr className="border-b h-12" key={don._id}>
                    <td>{don.fullName}</td>
                    <td>{don.emailDon}</td>
                    <td>{don.phoneDon} </td>
                    <td>{don.totalDon} </td>
                    {/* <td>{don.isPaid ? "PAID" : "NOT PAID"}</td> */}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </AdminLayout>
  );
}

export default DonationsScreen;
